<template>
  <div>
    <vx-card>
      <vs-row>
        <vs-col vs-justify="flex-start" vs-w="5.9">
          <h4>On Board Table</h4>
        </vs-col>
        <vs-col vs-w="4.1" vs-type="flex" >
          <!-- <vs-radio v-model="selectedSpocRadio" vs-name="selectedSpocRadio" vs-value="CPA"><b
              style="color: #7367f0">CPA</b></vs-radio>&nbsp;&nbsp;
          <vs-radio v-model="selectedSpocRadio" vs-name="selectedSpocRadio" vs-value="CMA"><b
              style="color: #7367f0">CMA</b></vs-radio> -->
              <v-select class="w-full" placeholder="Course"  :options="courses_list"  v-model="selectedSpocRadio"></v-select>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <vs-dropdown class="gap" vs-custom-content vs-trigger-click :key="dropdownKey">
            <vs-button radius color="dark" type="gradient" icon="search"></vs-button>
            <vs-dropdown-menu class="loginx">
              <vs-row vs-w="12">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <vs-input type="text" label-placeholder="Name" v-model="search_by_name" />
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="analyzePagination()"></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="9">
                  <vs-input type="text" label-placeholder="Can-Id" v-model="search_by_can_id"
                    :style="{ width: canIdWidth }" />
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="analyzePagination()"></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <vs-input type="text" label-placeholder="Mobile" v-model="search_by_mobile" />
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="analyzePagination()"></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <vs-input type="text" label-placeholder="Email" v-model="search_by_email" />
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="analyzePagination()"></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-top: 7%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <v-select class="w-full" placeholder="Agreement Status" :options="agreementOptions"
                    v-model="agreement_status"></v-select>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="analyzePagination()"></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-top: 7%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <!-- <vs-input
                                  type="text"
                                  label-placeholder="City"
                                  v-model="city"
                              /> -->
                  <v-select class="w-full" placeholder="City" :options="cityOptions" v-model="cities"></v-select>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="analyzePagination()"></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-top: 7%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <!-- <vs-input
                                  type="text"
                                  label-placeholder="City"
                                  v-model="city"
                              /> -->
                  <v-select class="w-full" placeholder="Follow-Up Done" :options="followOptions"
                    v-model="follow_up_done"></v-select>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="analyzePagination()"></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-top: 7%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <!-- <vs-input
                                  type="text"
                                  label-placeholder="City"
                                  v-model="city"
                              /> -->
                  <v-select class="w-full" placeholder="Follow-Up NotDone" :options="followNotOptions"
                    v-model="follow_up_notdone"></v-select>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="analyzePagination()"></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-top: 7%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <v-select class="w-full" placeholder="LMS Access" :options="lmsAccessOptions"
                    v-model="lms_access"></v-select>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="analyzePagination()"></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-top: 7%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <v-select class="w-full" placeholder="Books" :options="booksOptions" v-model="books"></v-select>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="analyzePagination()"></vs-button>
                </vs-col>
              </vs-row>
              <!-- <vs-row vs-w="12" style="margin-top: 7%">
                          <vs-col
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="center"
                              vs-w="9"
                          >
                              <v-select
                                  class="w-full"
                                  placeholder="Wiley OTB"
                                  :options="wileyOtbOptions"
                                  v-model="wiley_otb"
                              ></v-select>
                          </vs-col>
                          <vs-col
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-end"
                              vs-w="3"
                          >
                              <vs-button
                                  color="dark"
                                  type="gradient"
                                  icon="search"
                                  @click="analyzePagination()"
                              ></vs-button>
                          </vs-col>
                      </vs-row> -->
              <vs-row vs-w="12" style="margin-top: 7%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <v-select class="w-full" placeholder="Referrals" :options="referralOptions"
                    v-model="referral"></v-select>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="analyzePagination()"></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-top: 7%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <v-select class="w-full" placeholder="WA Group" :options="waGroupOptions"
                    v-model="wa_group"></v-select>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="analyzePagination()"></vs-button>
                </vs-col>
              </vs-row>
              <!-- <vs-row vs-w="12" style="margin-top: 7%">
                          <vs-col
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="center"
                              vs-w="9"
                          >
                              <v-select
                                  class="w-full"
                                  placeholder="Telegram 1"
                                  :options="telegramSGP1Options"
                                  v-model="telegram_study_group_p1"
                              ></v-select>
                          </vs-col>
                          <vs-col
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-end"
                              vs-w="3"
                          >
                              <vs-button
                                  color="dark"
                                  type="gradient"
                                  icon="search"
                                  @click="analyzePagination()"
                              ></vs-button>
                          </vs-col>
                      </vs-row> -->
              <!-- <vs-row vs-w="12" style="margin-top: 7%">
                          <vs-col
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="center"
                              vs-w="9"
                          >
                              <v-select
                                  class="w-full"
                                  placeholder="Telegram 2"
                                  :options="telegramSGP2Options"
                                  v-model="telegram_study_group_p2"
                              ></v-select>
                          </vs-col>
                          <vs-col
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-end"
                              vs-w="3"
                          >
                              <vs-button
                                  color="dark"
                                  type="gradient"
                                  icon="search"
                                  @click="analyzePagination()"
                              ></vs-button>
                          </vs-col>
                      </vs-row> -->
              <vs-row vs-w="12" style="margin-top: 7%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <v-select class="w-full" placeholder="IMA Scholarship" :options="IMAScholarshipOptions"
                    v-model="ima_scholarship"></v-select>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="analyzePagination()"></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-top: 7%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <v-select class="w-full" placeholder="Evaluation" :options="evaluationOptions"
                    v-model="evaluation"></v-select>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="analyzePagination()"></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-top: 7%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <v-select class="w-full" placeholder="CR Connect" :options="crConnectOptions"
                    v-model="cr_connect"></v-select>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="analyzePagination()"></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <vs-input type="text" label-placeholder="IMA Membership" v-model="ima_membership_id" />
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="analyzePagination()"></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <vs-input type="text" label-placeholder="Batch" v-model="batch" />
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="analyzePagination()"></vs-button>
                </vs-col>
              </vs-row>
            </vs-dropdown-menu>
          </vs-dropdown>
          <vs-button radius class="gap" color="dark" type="gradient" icon="refresh" @click="refreshdata"></vs-button>
        </vs-col>
      </vs-row>
      <vs-row vs-w="12" style="margin-top: 1%">

        <vs-col vs-type="flex" vs-w="4" style="margin-top: 1.2%">
          <treeselect v-model="initSelectedCM" :multiple="true" :options="treeDataCM" placeholder="CM Team"
            style="width: 300px" />
        </vs-col>

        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <!-- <label>Enrollment Date &nbsp;&nbsp;</label> -->
          <div>
            <label style="margin-top:2px;">Enrollment Date &nbsp;&nbsp;</label>
            <date-range-picker ref="picker" :opens="'left'" :locale-data="{
            firstDay: 1,
            format: 'DD-MMM-YYYY',
            customRangeLabel: 'Custom Range',
          }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false" :timePicker24Hour="true"
              :showWeekNumbers="false" :showDropdowns="false" :autoApply="false" v-model="dateRange"
              style="width: 200px; margin-right: 2%"></date-range-picker>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" style="margin-top: 1.2%">
          <!-- <vs-select
            placeholder="Search Level"
            autocomplete
            v-model="selected_level"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.value"
              v-for="(item, index) in levels_list"
            />
          </vs-select> -->
          <treeselect v-model="selected_level" :multiple="true" :options="levels_list" placeholder="Search Level"
            style="width: 200px" />
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-w="4" style="margin-top: 1.2%">
          <treeselect v-model="initSelectedBDE" :multiple="true" :options="treeDataBDE" placeholder="SR Spocs"
            style="width: 300px" />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <div>
            <label style="margin-top:2px;">SR LastCall Date &nbsp;&nbsp;</label>
            <!-- <label class="labell" style="margin-top:2px;">Enrollment month and year</label> -->
            <date-range-picker ref="picker" :ranges="ranges" :opens="'left'" :locale-data="{
            firstDay: 1,
            format: 'DD-MMM-YYYY',
            customRangeLabel: 'Custom Range',
          }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false" :timePicker24Hour="true"
              :showWeekNumbers="false" :showDropdowns="false" :autoApply="false" v-model="srDateRange"
              style="width: 200px; margin-right: 2%"></date-range-picker>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <div>
            <label style="margin-top:2px;">SR Next Call Date &nbsp;&nbsp;</label>
            <!-- <label class="labell" style="margin-top:2px;">Enrollment month and year</label> -->
            <date-range-picker ref="picker" :opens="'left'" :ranges="next_call_date_ranges" :locale-data="{
            firstDay: 1,
            format: 'DD-MMM-YYYY',
            customRangeLabel: 'Custom Range',
          }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false" :timePicker24Hour="true"
              :showWeekNumbers="false" :showDropdowns="false" :autoApply="false" v-model="srNextCallDateRange"
              style="width: 200px; margin-right: 2%"></date-range-picker>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <vs-button radius class="gap" color="dark" type="gradient" @click="analyzePagination()">Go</vs-button>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 3%">
        <vs-col vs-w="12">
          <vs-card>
            <div style="
                  width: 100%;
                  max-height: 80vh;
                  height: auto;
                  overflow: auto;
                  margin-left: 10px;
                ">
              <table width="700px">
                <thead>
                  <tr>
                    <th style="min-width: 75px; width: 75px" class="col-id-no fixed-header">
                      Can-ID
                    </th>
                    <th style="min-width: 100px; width: 100px" class="col-first-name fixed-header">
                      Name
                    </th>
                    <th style="min-width: 100px; width: 100px">
                      {{ this.selectedSpocRadio }} Course
                    </th>
                    <th style="min-width: 100px; width: 100px">Level</th>
                    <th style="min-width: 100px; width: 100px">Batch</th>
                    <th style="min-width: 100px; width: 100px">City</th>
                    <th style="min-width: 100px; width: 100px">Agreed Cost</th>
                    <th style="min-width: 100px; width: 100px">Amount Paid</th>
                    <th style="min-width: 100px; width: 100px">
                      Amount Pending
                    </th>

                    <th style="min-width: 100px; width: 100px">Enrolled By</th>
                    <th style="min-width: 100px; width: 100px">CM Spoc</th>
                    <th style="min-width: 100px; width: 100px">SR Spoc</th>
                    <th style="min-width: 100px; width: 100px">
                      Enrollment Date
                    </th>
                    <th style="min-width: 100px; width: 100px">
                      Agreement Status
                    </th>
                    <th style="min-width: 100px; width: 100px">
                      Whatsapp Group
                    </th>
                    <th style="min-width: 100px; width: 100px">LMS Access</th>
                    <th style="min-width: 100px; width: 100px">Books</th>
                    <th style="min-width: 100px; width: 100px" v-if="this.selectedSpocRadio === 'CMA'">
                      IMA Membership ID
                    </th>
                    <th style="min-width: 100px; width: 100px" v-if="this.selectedSpocRadio === 'CMA'">
                      IMA scholarship
                    </th>
                    <th style="min-width: 100px; width: 100px" v-if="this.selectedSpocRadio === 'CPA'">
                      Evaluation
                    </th>
                    <th style="min-width: 100px; width: 100px">Referral</th>
                    <th style="min-width: 100px; width: 100px">CR Connect</th>
                    <th style="min-width: 100px; width: 100px">Info</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(tr, index) in TableData" :key="index">
                    <td class="col-id-no" scope="row">{{ tr.identity }}</td>
                    <td class="col-first-name" scope="row">
                      {{ tr.person_name }}
                    </td>
                    <td>{{ tr.course }}</td>
                    <td>{{ tr.level }}</td>
                    <td>{{ tr.batch }}</td>
                    <td>
                      {{ tr.city }}
                    </td>
                    <td>
                      {{ tr.agreed_cost }}
                    </td>
                    <td>
                      {{ tr.amount_paid + tr.amount_in_process }}
                    </td>
                    <td>
                      <!-- {{ tr.amount_pending }} -->
                      {{ tr.agreed_cost - (tr.amount_paid + tr.amount_in_process) }}
                    </td>
                    <td>
                      {{ tr.added_by_name }}
                    </td>
                    <td>
                      {{ tr.spoc_name }}
                    </td>
                    <td>
                      {{ tr.sr_name }}
                    </td>
                    <td>
                      {{ convertTimestampToDate(tr.enrollment_date) }}
                    </td>
                    <td>
                      {{ checkstatus(tr.agreed_status) }}
                    </td>
                    <td>
                      {{ checkstatus(tr.wa_group) }}
                    </td>
                    <td>
                      {{ checkstatus(tr.lms_access) }}
                    </td>
                    <td>
                      {{ checkstatus(tr.books) }}
                    </td>
                    <td v-if="tr.course === 'CMA'">
                      {{ tr.ima_membership_id }}
                    </td>
                    <td v-if="tr.course === 'CMA'">
                      {{ checkstatus(tr.ima_scholarship) }}
                    </td>
                    <td v-if="tr.course === 'CPA'">
                      {{ checkstatus(tr.evaluation) }}
                    </td>
                    <td>
                      {{ checkstatus(tr.referral) }}
                    </td>
                    <td>
                      {{ checkstatus(tr.cr_connect) }}
                    </td>
                    <td>
                      <vs-row>
                        <div @click="openPopupEmit(tr.mwb_id)">
                          <vs-icon icon="info_outline" size="small" color="dark"></vs-icon>
                        </div>
                      </vs-row>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <vs-row style="margin-top: 3%">
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
                <vs-chip color="primary" style="margin-right: 2%">
                  <b>{{ countsleads }}</b>
                </vs-chip>
                <vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
              </vs-col>
            </vs-row>
          </vs-card>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../constants.json";
import EventBus from "../components/eventbus.js";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  data: () => ({
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'This month': [moment().startOf('month'), moment().endOf('month')],
      'This year': [moment().startOf('year'), moment().endOf('year')],
      'Last week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
      'Last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'Last 10 Days': [moment().subtract(10, 'days'), moment().subtract(1, 'days')],
      'Last 15 Days': [moment().subtract(15, 'days'), moment().subtract(1, 'days')],
      'Last 30 Days': [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
      'Last 60 Days': [moment().subtract(60, 'days'), moment().subtract(1, 'days')],
      'Last 90 Days': [moment().subtract(90, 'days'), moment().subtract(1, 'days')],
    },
    next_call_date_ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'This month': [moment().startOf('month'), moment().endOf('month')],
      'This year': [moment().startOf('year'), moment().endOf('year')],
      'Last week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
      'Last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'Last 10 Days': [moment().subtract(10, 'days'), moment().subtract(1, 'days')],
      'Last 15 Days': [moment().subtract(15, 'days'), moment().subtract(1, 'days')],
      'Last 30 Days': [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
      'Last 60 Days': [moment().subtract(60, 'days'), moment().subtract(1, 'days')],
      'Last 90 Days': [moment().subtract(90, 'days'), moment().subtract(1, 'days')],
    },
    dropdownKey: 0,
    selected_level: [],
    levels_list: [
      {
        id: "All",
        label: "All",
        children: [
          { id: "M10", label: "M10" },
          { id: "M9", label: "M9" },
          {
            id: "M9-", label: "M9-",
          },
          { id: "M8", label: "M8" },
          { id: "M8+", label: "M8+" },
          {
            id: "M8-", label: "M8-",
          },
          { id: "M7X", label: "M7X" },
          { id: "M7D2", label: "M7D2" },
          { id: "M7D1", label: "M7D1" },
          { id: "M7+", label: "M7+" },
          { id: "M7#", label: "M7#" },
          {
            id: "M7-JJ", label: "M7-JJ",
          },
          { id: "M7-", label: "M7-" },
          { id: "M7", label: "M7" },
          { id: "M7-IR",label: "M7-IR" },
          { id: "M7-INT",label: "M7-INT" },
        ],
      },

    ],
    lmsAccessOptions: ["Yes", "No"],
    lms_access: "",
    booksOptions: ["Yes", "No"],
    books: "",
    IMAScholarshipOptions: ["Yes", "No"],
    ima_scholarship: "",
    waGroupOptions: ["Yes", "No"],
    wa_group: "",
    wileyOtbOptions: ["Yes", "No"],
    wiley_otb: "",
    referralOptions: ["Yes", "No"],
    referral: "",
    telegramSGP1Options: ["Yes", "No"],
    telegram_study_group_p1: "",
    telegramSGP2Options: ["Yes", "No"],
    telegram_study_group_p2: "",
    evaluationOptions: ["Yes", "No"],
    evaluation: "",
    crConnectOptions: ["Yes", "No"],
    cr_connect: "",
    selectedSpocRadio: "CPA",
    CourseTableData: [],
    tablelinks: 0,
    currentTablePage: 1,
    dateRange: {
      startDate: "",
      endDate: "",
    },
    srDateRange: {
      startDate: "",
      endDate: "",
    },
    srNextCallDateRange: {
      startDate: "",
      endDate: "",
    },
    search_by_name: "",
    search_by_can_id: "",
    search_by_mobile: "",
    search_by_email: "",
    cities: "",
    cityOptions: [
      "Ahmedabad",
      "Bangalore",
      "Chennai",
      "Delhi",
      "Ernakulam",
      "Hyderabad",
      "Kolkata",
      "Mumbai",
      "Pune",
    ],
    batch: "",
    courses_list:['CPA','CMA','Bridge Course – Public Accounting'],
    canIdWidth: "100%",
    TableData: "",
    countsleads: "",
    initSelectedBDE: [],
    initSelectedCM: [],
    rawcities: [],
    treeDataBDE: [
      {
        id: "All",
        label: "All",
        children: [],
      },
    ],
    treeDataCM: [
      {
        id: "All",
        label: "All",
        children: [],
      },
    ],
    rawBde: [],
    cmrawBde: [],
    searchIn: {
      id: "",
      name: "",
    },
    searchLms: "",
    searchBooks: "",
    searchwiley_otb: "",
    searchreferral: "",
    searchwa_group: "",
    searchtelegram_study_group_p1: "",
    searchtelegram_study_group_p2: "",
    searchima_scholarship: "",
    searchevaluation: "",
    searchcr_connect: "",
    ima_membership_id: "",
    agreement_status: "",
    agreementOptions: ["Yes", "No"],
    searchagreement_status: "",
    followOptions: ["e_1 Done", "e_7 Done", "e_14 Done", "e_21 Done"],
    follow_up_done: "",
    followNotOptions: [
      "e_1 Not Done",
      "e_7 Not Done",
      "e_14 Not Done",
      "e_21 Not Done",
    ],
    follow_up_notdone: "",
    searchfollow_up_done: "",
    searchfollow_up_done_e_7: "",
    searchfollow_up_done_e_14: "",
    searchfollow_up_done_e_21: "",
    login_spoc_id:"",
  }),
  components: {
    DateRangePicker,
    "v-select": vSelect,
    Treeselect,
  },
  mounted() {
    this.login_spoc_id = localStorage.getItem("spoc_id");
    this.clear();
    this.analyzePagination(1);
    this.getSpocs();
    this.getSRSpocs();
    EventBus.$on("reload-onboard", () => {
      this.analyzePagination(this.currentTablePage);
    });
  },
  watch: {
    currentTablePage(val) {
      // console.log("current page", val);
      this.analyzePagination(val);
    },
    selectedSpocRadio(val) {
      if (val === "CPA") {
        this.selectedSpocRadio = "CPA";
        this.analyzePagination();
      } else if (val === "CMA") {
        this.selectedSpocRadio = "CMA";
        this.analyzePagination();
      } else if (val === "Bridge Course – Public Accounting") {
        this.selectedSpocRadio = "Bridge Course – Public Accounting";
        this.analyzePagination();
      }
    },
    initSelectedCM() {
      // this.searchAPI();
    },
    initSelectedBDE() {
      // this.searchAPI();
    },

    lms_access(val) {
      if (val === "Yes") {
        this.searchLms = 1;
      } else if (val === "No") {
        this.searchLms = 0;
      }
    },
    books(val) {
      if (val === "Yes") {
        this.searchBooks = 1;
      } else if (val === "No") {
        this.searchBooks = 0;
      }
    },
    wiley_otb(val) {
      if (val === "Yes") {
        this.searchwiley_otb = 1;
      } else if (val === "No") {
        this.searchwiley_otb = 0;
      }
    },
    referral(val) {
      if (val === "Yes") {
        this.searchreferral = 1;
      } else if (val === "No") {
        this.searchreferral = 0;
      }
    },
    wa_group(val) {
      if (val === "Yes") {
        this.searchwa_group = 1;
      } else if (val === "No") {
        this.searchwa_group = 0;
      }
    },
    telegram_study_group_p1(val) {
      if (val === "Yes") {
        this.searchtelegram_study_group_p1 = 1;
      } else if (val === "No") {
        this.searchtelegram_study_group_p1 = 0;
      }
    },
    telegram_study_group_p2(val) {
      if (val === "Yes") {
        this.searchtelegram_study_group_p2 = 1;
      } else if (val === "No") {
        this.searchtelegram_study_group_p2 = 0;
      }
    },
    ima_scholarship(val) {
      if (val === "Yes") {
        this.searchima_scholarship = 1;
      } else if (val === "No") {
        this.searchima_scholarship = 0;
      }
    },
    evaluation(val) {
      if (val === "Yes") {
        this.searchevaluation = 1;
      } else if (val === "No") {
        this.searchevaluation = 0;
      }
    },
    cr_connect(val) {
      if (val === "Yes") {
        this.searchcr_connect = 1;
      } else if (val === "No") {
        this.searchcr_connect = 0;
      }
    },
    agreement_status(val) {
      if (val === "Yes") {
        this.searchagreement_status = 1;
      } else if (val === "No") {
        this.searchagreement_status = 0;
      }
    },
    follow_up_done(val) {
      if (val === "e_1 Done") {
        this.searchfollow_up_done = 1;
      } else if (val === "e_7 Done") {
        this.searchfollow_up_done_e_7 = 1;
      } else if (val === "e_14 Done") {
        this.searchfollow_up_done_e_14 = 1;
      } else if (val === "e_21 Done") {
        this.searchfollow_up_done_e_21 = 1;
      }
    },
    follow_up_notdone(val) {
      if (val === "e_1 Not Done") {
        this.searchfollow_up_done = 0;
      } else if (val === "e_7 Not Done") {
        this.searchfollow_up_done_e_7 = 0;
      } else if (val === "e_14 Not Done") {
        this.searchfollow_up_done_e_14 = 0;
      } else if (val === "e_21 Not Done") {
        this.searchfollow_up_done_e_21 = 0;
      }
    },
  },
  methods: {
    openPopupEmit(id) {
      this.openBigPopup(id);
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      if (isNaN(ms)) {
        let str = "";
        return str;
      }
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    analyzePagination(val) {
      this.dropdownKey = Math.random()
      if (this.currentTablePage === 1) {
        this.searchAPI(1);
      } else {
        this.searchAPI(val);
      }
    },
    getSpocs() {
      this.initSelectedCM = [];
      this.treeDataCM[0].children = [];
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("data", response);
          this.loggedin = response.data.logged_in_user_id;
          this.cm_spoc_names = response.data.spocs;
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          this.cmSpocCalling(response.data.spocs);
          // this.calling(this.spoc_names);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSRSpocs() {
      this.initSelectedBDE = [];
      this.treeDataBDE[0].children = [];
      let url = `${constants.ADMIN_SERVER_API}getSRSpocs`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.loggedin = response.data.logged_in_user_id;
          this.spoc_names = response.data.spocs;
          // this.cmSpocCalling(response.data.spocs);
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          this.calling(response.data.spocs);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    calling(spoc_names) {
      this.rawBde = spoc_names;
      this.treeDataBDE[0].children = [];
      var unique = spoc_names
        .map((name) => {
          return {
            count: 1,
            name: name.city,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          this.rawcities.push(key);
          var obj = {
            id: key,
            label: key,
            children: [],
          };
          this.treeDataBDE[0].children.push(obj);
        }
      }
      spoc_names.forEach((server_spoc) => {
        this.treeDataBDE.forEach((bde) => {
          bde.children.forEach((bdechid) => {
            // if (bdechid.label === server_spoc.city) {
            //   var obj = {
            //     id: server_spoc.full_name,
            //     label: server_spoc.full_name,
            //   };
            //   bdechid.children.push(obj);
            // }
            if (bdechid.label === server_spoc.city) {
                  if(this.login_spoc_id == 132 ||this.login_spoc_id == 937 || this.login_spoc_id == 39){
                    let obj = {
                    id: server_spoc.full_name,
                    label: server_spoc.full_name,
                  };
                  bdechid.children.push(obj);
                  }
                  else{
                    if(server_spoc.full_name.substring(0,1) != 'X'){
                      let obj = {
                        id: server_spoc.full_name,
                        label: server_spoc.full_name,
                      };
                      bdechid.children.push(obj);
                      }
                  }
                
                }
          });
        });
      });
      this.treeDataBDE[0].children = this.sortNestedArrayAlphabetically(
        this.treeDataBDE[0].children
      );
      // this.searchAPI(1);
    },
    cmSpocCalling(cm_spoc_names) {
      this.cmrawBde = cm_spoc_names;
      this.treeDataCM[0].children = [];
      var unique = cm_spoc_names
        .map((name) => {
          return {
            count: 1,
            name: name.city,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          this.rawcities.push(key);
          var obj = {
            id: key,
            label: key,
            children: [],
          };
          this.treeDataCM[0].children.push(obj);
        }
      }
      cm_spoc_names.forEach((server_spoc) => {
        this.treeDataCM.forEach((cmbde) => {
          cmbde.children.forEach((bdechid) => {
            // if (bdechid.label === server_spoc.city) {
            //   var obj = {
            //     id: server_spoc.full_name,
            //     label: server_spoc.full_name,
            //   };
            //   bdechid.children.push(obj);
            // }
            if (bdechid.label === server_spoc.city) {
                  if(this.login_spoc_id == 132 ||this.login_spoc_id == 937 || this.login_spoc_id == 39){
                    let obj = {
                    id: server_spoc.full_name,
                    label: server_spoc.full_name,
                  };
                  bdechid.children.push(obj);
                  }
                  else{
                    if(server_spoc.full_name.substring(0,1) != 'X'){
                      let obj = {
                        id: server_spoc.full_name,
                        label: server_spoc.full_name,
                      };
                      bdechid.children.push(obj);
                      }
                  }
                
                }
          });
        });
      });
      this.treeDataCM[0].children = this.sortNestedArrayAlphabetically(
        this.treeDataCM[0].children
      );

      // console.log(this.searchIn);
      // this.searchAPI(1);
    },
    searchAPI(page) {
      this.$vs.loading();
      let bde = [];
      let cmBde = [];
      this.initSelectedBDE.forEach((sort) => {
        if (sort === "All") {
          this.treeDataBDE[0].children.forEach((child) => {
            child.children.forEach((acutal_spoc) => {
              bde.push(acutal_spoc.label);
            });
          });
        } else {
          let city = false;
          this.treeDataBDE[0].children.forEach((child) => {
            if (child.label === sort) {
              child.children.forEach((acutal_spoc) => {
                bde.push(acutal_spoc.label);
              });
              city = true;
            }
          });
          if (city) {
            return true;
          } else {
            bde.push(sort);
          }
        }
      });
      let bdeid = [];
      bde.forEach((spoc) => {
        this.rawBde.forEach((raw) => {
          if (raw.full_name === spoc) {
            bdeid.push(raw.id);
          }
        });
      });
      this.initSelectedCM.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCM[0].children.forEach((child) => {
            child.children.forEach((acutal_spoc) => {
              cmBde.push(acutal_spoc.label);
            });
          });
        } else {
          let city = false;
          this.treeDataCM[0].children.forEach((child) => {
            if (child.label === sort) {
              child.children.forEach((acutal_spoc) => {
                cmBde.push(acutal_spoc.label);
              });
              city = true;
            }
          });
          if (city) {
            return true;
          } else {
            cmBde.push(sort);
          }
        }
      });
      let cmBdeId = [];

      cmBde.forEach((spoc) => {
        // console.log("bde", spoc);
        this.cmrawBde.forEach((raw) => {
          // console.log("bde", raw.first_name);
          if (raw.full_name === spoc) {
            cmBdeId.push(raw.id);
          }
        });
      });

      let levelId = [];
      this.selected_level.forEach((sort) => {
        if (sort === "All") {
          this.levels_list[0].children.forEach((child) => {
            levelId.push(child.id);
          });
        }
        else {
          this.levels_list[0].children.forEach((child) => {
            if (child.label === sort) {
              levelId.push(child.id);
            }
          });
        }
      });

      // data.sr_id = bdeid.join();
      // let url = `https://milescrmdev.2x2.ninja/api/queryOnBoardingData`;
      let url = `${constants.SERVER_API}queryOnBoardingData`;
      let data = {
        selected_level: levelId.join(),
        page: page,
        can_name: this.search_by_name,
        course: this.selectedSpocRadio,
        identity: this.search_by_can_id,
        mobile: this.search_by_mobile,
        email: this.search_by_email,
        batch: this.batch,
        sr_id: bdeid.join(),
        spoc_id: cmBdeId.join(),
        city: this.cities,
        enrollment_from_date: this.datatoTimestamp(this.dateRange.startDate),
        enrollment_to_date: this.datatoTimestamp(this.dateRange.endDate),
        last_call_from_date: this.datatoTimestamp(this.srDateRange.startDate),
        last_call_to_date: this.datatoTimestamp(this.srDateRange.endDate),
        next_call_from_date: this.datatoTimestamp(this.srNextCallDateRange.startDate),
        next_call_to_date: this.datatoTimestamp(this.srNextCallDateRange.endDate),
        lms_access: this.searchLms,
        books: this.searchBooks,
        wiley_otb: this.searchwiley_otb,
        referral: this.searchreferral,
        wa_group: this.searchwa_group,
        telegram_study_group_p1: this.searchtelegram_study_group_p1,
        telegram_study_group_p2: this.searchtelegram_study_group_p2,
        ima_membership_id: this.ima_membership_id,
        ima_scholarship: this.searchima_scholarship,
        evaluation: this.searchevaluation,
        cr_connect: this.searchcr_connect,
        agreed_status: this.searchagreement_status,
        e_1: this.searchfollow_up_done,
        e_7: this.searchfollow_up_done_e_7,
        e_14: this.searchfollow_up_done_e_14,
        e_21: this.searchfollow_up_done_e_21,
      };
      // console.log(data);
      axios
        .get(url, {
          params: data,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          // console.log(
          //     "getOnBoardDetails data",
          //     response.data.onboarddata
          // );
          this.TableData = response.data.onboarddata.data;
          this.currentTablePage = response.data.onboarddata.current_page;
          this.tablelinks = response.data.onboarddata.last_page;
          this.countsleads = response.data.onboarddata.total;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    clear() {
      this.search_by_name = "";
      this.search_by_can_id = "";
      this.search_by_mobile = "";
      this.search_by_email = "";
      this.batch = "";
      this.cities = "";
      this.dateRange.startDate = "";
      this.dateRange.endDate = "";
      this.enrollment_from_date = "";
      this.enrollment_to_date = "";
      this.srDateRange.startDate = "";
      this.srDateRange.endDate = "";
      this.srNextCallDateRange.startDate = "";
      this.srNextCallDateRange.endDate = "";
      this.last_call_from_date = "";
      this.last_call_to_date = "";
      this.lms_access = "";
      this.books = "";
      this.wiley_otb = "";
      this.referral = "";
      this.wa_group = "";
      this.telegram_study_group_p1 = "";
      this.telegram_study_group_p2 = "";
      this.ima_membership_id = "";
      this.ima_scholarship = "";
      this.evaluation = "";
      this.cr_connect = "";
      this.agreement_status = "";
      this.follow_up_done = "";
      this.follow_up_notdone = "";
    },
    refreshdata() {
      this.search_by_name = "";
      this.search_by_can_id = "";
      this.search_by_mobile = "";
      this.search_by_email = "";
      this.batch = "";
      this.cities = "";
      this.dateRange.startDate = "";
      this.dateRange.endDate = "";
      this.enrollment_from_date = "";
      this.enrollment_to_date = "";
      this.srDateRange.startDate = "";
      this.srDateRange.endDate = "";
      this.srNextCallDateRange.startDate = "";
      this.srNextCallDateRange.endDate = "";
      this.last_call_from_date = "";
      this.last_call_to_date = "";
      this.searchLms = "";
      this.searchBooks = "";
      this.searchwiley_otb = "";
      this.searchreferral = "";
      this.searchwa_group = "";
      this.searchtelegram_study_group_p1 = "";
      this.searchtelegram_study_group_p2 = "";
      this.ima_membership_id = "";
      this.searchima_scholarship = "";
      this.searchevaluation = "";
      this.searchcr_connect = "";
      this.searchagreement_status = "";
      this.searchfollow_up_done = "";
      this.searchfollow_up_done_e_7 = "";
      this.searchfollow_up_done_e_14 = "";
      this.searchfollow_up_done_e_21 = "";
      this.initSelectedBDE = [];
      this.initSelectedCM = [];
      this.clear();
      this.analyzePagination(1);
    },
    tsToDate(timestamp) {
      var ts = new Date(timestamp * 1000);
      return ts.toDateString().slice(4);
    },
    checkstatus(val) {
      if (val === 0) {
        return "NO";
      } else if (val === 1) {
        return "YES";
      }
    },
  },
};
</script>

<style scoped>
thead th {
  top: 0;
  position: sticky;
  z-index: 20;
  background-color: white;
  color: black;
}

.col-id-no {
  left: 0;
  position: sticky;
  background-color: white;
}

.col-first-name {
  left: 80px;
  position: sticky;
  background-color: white;
}

.fixed-header {
  z-index: 50;
}

table th,
td {
  outline: 1px solid #ccc;
}
</style>
